import React, { useEffect, useState } from "react";
import "./App.scss";

import { ReactComponent as Instagram } from "./svg/instagram.svg";
import { ReactComponent as Facebook } from "./svg/facebook.svg";
import ArtistsOverview from "./Components/ArtistsOverview/ArtistsOverview";
import Header from "./Components/Header/Header";

function App() {
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const isUserScrolled = window.scrollY > 100;
            setIsScrolled(isUserScrolled);
        };

        // Add scroll event listener
        window.addEventListener("scroll", handleScroll);

        // Cleanup function
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <div className="app">
            <div id="top" />
            <Header isScrolled={isScrolled} />
            <div className="main-container container text-center">
                <div className="row" id="intro">
                    <div className="col pb-3">
                        <img src="./images/logo-feestival-2000.png" alt="Feestival Deurne" className="logo mb-3" />
                        <h1>Feestival is helaas voorbij</h1>
                        <p>Het was prachtig en gelukkig hebben we de foto's nog! Bekijk ze via de volgende links:</p>
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col-12 col-sm-6 col-lg-4 offset-lg-2">
                        <a href="https://fotos.peelstrekels.nl/-/fotos/2023-2024/feestival-zaterdag" target="_blank" className="btn btn-yellow w-100 mb-3" rel="noreferrer">
                            Foto's zaterdag
                        </a>
                    </div>
                    <div className="col-12 col-sm-6 col-lg-4">
                        <a href="https://fotos.peelstrekels.nl/-/fotos/2023-2024/feestival-zondag" target="_blank" className="btn btn-yellow w-100 mb-3" rel="noreferrer">
                            Foto's zondag
                        </a>
                    </div>
                </div>
                <div className="row" id="ocean-feestival">
                    <div className="col">
                        <h2 className="mb-0">Feestival Club Night @ The Ocean</h2>
                        <h3 className="mb-3">Vrijdag 27 september</h3>
                    </div>
                </div>
                <div className="row mb-3">
                    <div className="col-12 col-sm-8 offset-sm-2 col-md-4 mb-3 mb-md-0">
                        <img src="./images/feestival-club-night.jpg" alt="Feestival Club Night @ The Ocean" className="img-fluid" />
                    </div>
                    <div className="col-12 col-sm-8 offset-sm-2 offset-md-0 col-md-4 mb-3 mb-md-0">
                        <img src="./images/jesse-hoefnagels-rhode-kok.jpg" alt="Jesse Hoefnagels & Rhode Kok" className="img-fluid" />
                    </div>
                </div>
                <div className="row mb-5">
                    <div className="col pb-3">
                        <p>
                            Jesse Hoefnagels & Rhodé Kok kwamen op vrijdag 27 september speciaal voor de Feestival club night naar The Ocean! Ook kwamen DJ Djensse & Mc Ruffess en DJ EIM langs voor de
                            vetste muziek en was er een photo wall. Een ticket kostte 5 euro inclusief onbeperkt drankjes.
                        </p>
                    </div>
                </div>
                <div className="row" id="feestival-deurne">
                    <div className="col pb-3">
                        <h2 className="mb-0">Feestival Deurne</h2>
                        <h3 className="mb-3">Zaterdag 28 september</h3>
                        <p>
                            De zaterdagavond was het hoogtepunt van de Feestiviteiten; in een doorlopend en avondvullend programma dat om stipt 19.00 uur begon trok een keur aan artiesten voorbij en
                            presentator Frans Feest loodste de feestvierenden door de bomvolle avond heen. Een kaartje kostte 15 euro in de voorverkoop en op de avond zelf betaalde je 20 euro. De line
                            up bood 100% non-stop feestgarantie van 19.00 tot 1.00 uur.
                        </p>
                        <a href="https://fotos.peelstrekels.nl/-/fotos/2023-2024/feestival-zaterdag" target="_blank" className="btn btn-yellow mt-3 mb-3" rel="noreferrer">
                            Bekijk de foto's van Feestival
                        </a>
                    </div>
                </div>
                <ArtistsOverview day={1} />
                <div className="row" id="jubileumfeest-de-peelstrekels">
                    <div className="col pb-3">
                        <h2 className="mb-0">Jubileumfeest de Peelstrekels</h2>
                        <h3 className="mb-3">Zondag 29 september</h3>
                        <p>
                            Op zondag nodigden de Peelstrekels iedereen uit die ooit iets heeft betekend voor carnaval in Deurne. Om 13.00 ging de tent open en volgden er optredens van de KHD Deurne,
                            dat dit jaar zijn 150 jarig jubileum viert, Marlon Kicken, cantusband Cantuccini, John Tana, Michiel Malschaert en Moonlight. Humor, muziek, herkenning en ontmoeting waren
                            kenmerkend voor deze middag die als onvergetelijk de geschiedenisboeken in is gegaan! Ook aan de kleinsten was gedacht met een springkussen.
                        </p>
                        <a href="https://fotos.peelstrekels.nl/-/fotos/2023-2024/feestival-zondag" target="_blank" className="btn btn-yellow mt-3 mb-3" rel="noreferrer">
                            Bekijk de foto's van het jubileumfeest
                        </a>
                    </div>
                </div>
                <ArtistsOverview day={2} />
            </div>
            <div id="footer" className="mt-auto mb-1 text-center">
                <div className="container">
                    <div className="row">
                        <div className="col mb-2">
                            <strong>Op de hoogte blijven?</strong>
                        </div>
                    </div>
                    <div className="row mb-4">
                        <div className="col text-center gap-4 d-flex justify-content-center align-items-center">
                            <a href="https://www.facebook.com/feestivaldeurne" className="social-link facebook" target="_blank" rel="noreferrer">
                                <Facebook />
                            </a>
                            <a href="https://www.instagram.com/feestivaldeurne" className="social-link instagram" target="_blank" rel="noreferrer">
                                <Instagram />
                            </a>
                        </div>
                    </div>
                    <div className="row mb-5">
                        <div className="col text-center gap-4 d-flex justify-content-center align-items-center">
                            <a href="/downloads/Feestival-Presskit.zip" className="download-presskit btn btn-yellow" download>
                                Download Presskit
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default App;
