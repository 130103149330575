import React from "react";
import ArtistSingle from "../ArtistSingle/ArtistSingle";
import { motion, Variants } from "framer-motion";

type OverviewProps = {
    day: number;
};

type Artist = {
    name: string;
    image: string;
    alt: string;
    intro?: string;
};

const artistsSaturday: Artist[] = [
    {
        name: "Blaas of Glory",
        image: "./images/artists/blaas-of-glory.png",
        alt: "Blaas of Glory",
        intro: "Helemaal uit Zwolle, weet al tien jaar het publiek te bekoren met muziek van onder andere AC/DC, Bon Jovi, Metallica en Van Halen, maar dan met blaasinstrumenten! Als Heavy Metal Marchingband vergaarden ze roem in heel Europa en stonden op alle toonaangevende Metalfeestivals en lieten een even prettige als verwoestende indruk achter.",
    },
    {
        name: "Henny Huisman<br />Mini Playback Show",
        image: "./images/artists/henny-huisman-vermelding-robin-kamphuis.png",
        alt: "Henny Huisman - Foto door Robin Kamphuis",
        intro: "Dé topact van Paaspop dit jaar. Krasse Knar Huisman laat oude tijden herleven in een bijzonder dynamische show, waarbij ook het publiek zeer nauw betrokken wordt op verrassende wijze.",
    },
    {
        name: "Ronnie Ruysdael",
        image: "./images/artists/ronnie-ruysdael.png",
        alt: "Ronnie Ruysdael",
        intro: "Bekend van de Johnnies staat voor feest en dat moet je letterlijk nemen. Meezingen, meedoen, polonaise niets is deze rasartiest vreemd en hij trekt alles uit de kast.",
    },
    {
        name: "Loco Loco<br >Disco Show",
        image: "./images/artists/loco-loco-vermelding-edwin-smits.png",
        alt: "Loco Loco - Foto door Edwin Smits",
        intro: "Wie kent ze niet? Plaatjes draaien, maar dan compleet anders; interactie met het publiek is hun core business en daarom zijn ze al meer dan tien jaar graag geziene gasten op popfestivals als Paaspop, Concert at Sea en de Zwarte Cross. Ze hebben daar zelfs hun eigen area!",
    },
    {
        name: "ABBA Tribute<br />A-Fever XS",
        image: "./images/artists/a-fever.png",
        alt: "A-Fever XS",
        intro: "Laat de tijden van de legendarische Zweedse formatie ABBA herleven. Voor velen jeugdsentiment, maar ook de jeugd komt volop aan zijn trekken met de tijdloze muziek van het iconische ABBA.",
    },
    {
        name: "Wilbert Pigmans",
        image: "./images/artists/wilbert-pigmans.png",
        alt: "Wilbert Pigmans",
        intro: "De toreador, behoeft geen verdere introductie, overal waar hij verschijnt staat het publiek binnen de kortste keren op de banken!",
    },
    {
        name: "Glenn van Dooren",
        image: "./images/artists/glenn-van-dooren.png",
        alt: "Glenn van Dooren",
        intro: "Met zijn brede repertoire en zijn talent als entertainer weet hij jong en oud te vermaken. Van zijn tv-debuut bij Bloed Zweet en Tranen tot zijn optredens als huiszanger bij bekende locaties,  Glenn weet altijd een lach op ieders gezicht te toveren!",
    },
    {
        name: "Frans Feest",
        image: "./images/artists/frans-feest.png",
        alt: "Frans Feest",
        intro: "Deze ras gangmaker, bekend van café D’n Borrel zorgt dat het op geen moment stil valt. Op aanstekelijke wijze houdt hij de vaart erin maakt er een avond van om nooit te vergeten!",
    },
];

const artistsSunday: Artist[] = [
    {
        name: "John Tana",
        image: "./images/artists/john-tana.png",
        alt: "John Tana",
        intro: "Deze Limburgse mega-artiest brengt Frans geïnspireerde nummers op geheel eigen wijze ten gehore. Hij pakt je met zijn warme stemgeluid op een humoristische en gevoelige manier volledig in en brengt je in hogere sferen. Dit moet je (ook als Brabander) minimaal 1 keer in je leven meemaken. John geeft een 100% feestgarantie en breekt de tent volledig af.",
    },
    {
        name: "KHD Deurne",
        image: "./images/artists/khd.png",
        alt: "KHD",
        intro: "Onze vrienden van de eveneens jubilerende KHD (150 jaar) zullen de middag op een ludieke wijze openen. Lopend vanuit de straten van Deurne zullen zij in onze tent nog een prachtige carnavalsmedley ten gehore brengen. Tijdens deze medley zullen enkele verrassende zangtalenten vocale ondersteuning bieden.",
    },
    {
        name: "Marlon Kicken",
        image: "./images/artists/marlon-kicken.png",
        alt: "Marlon Kicken",
        intro: "Marlon is een zeer bekende Nederlandse cabaretier/tonprater met Curaçaose roots. Hij beheerst het accent van zijn geboorteland en plat Helmonds en is niet vies van een flinke dosis zelfspot. Dit, in combinatie met een belachelijk kleedje, leidt tot hilarische en absurde grappen. Marlon heeft speciaal voor het jubileumfeest van de Peelstrekels een act geschreven waarmee hij ons gaat verrassen.",
    },
    {
        name: "Cantusband Cantuccini",
        image: "./images/artists/cantuccini.png",
        alt: "Cantusband Cantuccini",
        intro: "Een optreden van de cantusband is een spektakel waar je in opgezogen wordt. De 14 muzikanten (uit Deurne en omstreken) op ons podium maken er een gruwelijk meezing-feest van wat naar het schijnt prima te matchen is met het drinken van bier. Er zijn tekstboekjes en er is een bar, gas erop!",
    },
    {
        name: "Hosbengels",
        image: "./images/artists/hosbengels.png",
        alt: "Hosbengels",
        intro: "Een jubileumfeest van de Peelstrekels zonder de Hosbengels is ondenkbaar. Wij zijn dan ook erg blij dat onze hofkapel een aantal momenten van deze middag wil vullen met hun prachtige repertoire. Tis goe dé gullie der bént.",
    },
    {
        name: "Moonlight",
        image: "./images/artists/moonlight.png",
        alt: "Moonlight",
        intro: "Wij stellen aan je voor: Drie zeer getalenteerde jonge meiden (2 uit Deurne) die een breed repertoire aan meezing liedjes brengen, Engels- en Nederlandstalig. Deze dames gaan het nog heel ver brengen in de muziekwereld. Maar nu eerst het Feestivalpodium bestieren.",
    },
    {
        name: "Michiel Malschaert",
        image: "./images/artists/michiel-malschaert.png",
        alt: "Michiel Malschaert",
        intro: '"Muzikant uit de Peel" noemt hij zichzelf. Maar hij kan veel meer. Met zijn muzikale show-act raakt hij gegarandeerd ook jouw gevoelige snaar. In zijn oude tourbus doet hij zondag Deurne aan en zal op ons podium laten zien hoe groot een éénmans optreden kan zijn.',
    },
];

const artistVariants: Variants = {
    offscreen: {
        opacity: 0,
        y: 300,
    },
    onscreen: {
        opacity: 1,
        y: 0,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8,
        },
    },
};

function ArtistsOverview({ day }: OverviewProps) {
    const artists = day === 1 ? artistsSaturday : artistsSunday;

    return (
        <div className="artist-overview row pb-5 justify-content-center">
            {artists.map((artist, index) => {
                return (
                    <motion.div key={index} viewport={{ once: true }} variants={artistVariants} initial="offscreen" whileInView="onscreen" className="col-12 col-sm-6 col-md-4 mb-3">
                        <ArtistSingle key={artist.name} name={artist.name} image={artist.image} alt={artist.alt} intro={artist.intro} />
                    </motion.div>
                );
            })}
        </div>
    );
}

export default ArtistsOverview;
