import React from "react";
import "./ArtistSingle.scss";

type ArtistProps = {
    name: string;
    image: string;
    alt: string;
    intro?: string;
};

function ArtistSingle({ name, image, alt, intro }: ArtistProps) {
    return (
        <div className="artist">
            <div className="text">
                <h3 dangerouslySetInnerHTML={{ __html: name }} />
                {intro && <p className="intro">{intro}</p>}
            </div>
            <div className="picture">
                <img src={image} alt={alt} />
            </div>
        </div>
    );
}

export default ArtistSingle;
