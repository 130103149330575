import { ReactComponent as Instagram } from "../../svg/instagram.svg";
import { ReactComponent as Facebook } from "../../svg/facebook.svg";
import "./Header.scss";

export const Header = ({ isScrolled }: { isScrolled: boolean }) => {
    return (
        <header className={`header ${isScrolled ? "scroll" : ""} d-flex align-items-center`}>
            <div className="container">
                <div className="row">
                    <div className="col-6 col-md-4">
                        <a href="#top">
                            <img src="./images/logo-kaal.webp" alt="Feestival" className="header-logo" />
                        </a>
                    </div>
                    <div className="col-6 col-md-8 d-flex justify-content-end align-items-center">
                        <div className="social-media d-none d-md-flex gap-4">
                            <a href="https://www.facebook.com/feestivaldeurne" className="social-link facebook" target="_blank" rel="noreferrer">
                                <Facebook />
                            </a>
                            <a href="https://www.instagram.com/feestivaldeurne" className="social-link instagram" target="_blank" rel="noreferrer">
                                <Instagram />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Header;
